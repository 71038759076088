import React from "react"
// import { Link } from "gatsby"
import "../assets/css/api.css"


import Layout from "../components/layout"


import handIcon from "../images/hand.svg"
import chatbottom from "../images/chat-bottom-icon.svg"
import chatProfile from "../images/chat-profile.png"
import closeChat from "../images/close-icon.svg"

import CloudBanner from "../images/api/api-cloud-img.png"
import APIBanner from "../images/api/api-banner-img.png"

import SEO from "../components/seo"
const ApiPage = (props) => (
    <Layout rest={props} layoutType="main">
        <SEO title="Servicecontract.com" keywords={[`servicecontract.com`, `service contracts`, `Extended warranty`]} />
        <div className="chat-content" style={{ display: 'none' }}>
            <div className="chat-content-circle">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.496" viewBox="0 0 30 26.496">
                    <g id="Group_157" data-name="Group 157" transform="translate(-309.85 -157.354)">
                        <g id="Group_27" data-name="Group 27" transform="translate(309.85 157.354)">
                            <path id="Path_73" data-name="Path 73" d="M309.85,170.605c0,7.3,6.733,13.245,15,13.245a16.468,16.468,0,0,0,7.738-1.9,8.731,8.731,0,0,0,4.123.967,10.089,10.089,0,0,0,1.427-.1,1.14,1.14,0,0,0,.46-2.089,4.986,4.986,0,0,1-1.8-2.132,12.3,12.3,0,0,0,3.045-8c0-7.3-6.733-13.245-15-13.245S309.85,163.3,309.85,170.605Zm1.5,0c0-6.476,6.058-11.744,13.5-11.744s13.5,5.269,13.5,11.744a10.721,10.721,0,0,1-2.971,7.345.756.756,0,0,0-.153.772,6.667,6.667,0,0,0,1.795,2.7,7.254,7.254,0,0,1-4.044-.968.717.717,0,0,0-.386-.1.745.745,0,0,0-.373.1,14.929,14.929,0,0,1-7.37,1.911C317.409,182.349,311.351,177.081,311.351,170.605Z" transform="translate(-309.85 -157.354)" fill="#fff" />
                            <circle id="Ellipse_1" data-name="Ellipse 1" cx="1.924" cy="1.924" r="1.924" transform="translate(13.085 11.327)" fill="#fff" />
                            <circle id="Ellipse_2" data-name="Ellipse 2" cx="1.924" cy="1.924" r="1.924" transform="translate(17.943 11.327)" fill="#fff" />
                            <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.924" cy="1.924" r="1.924" transform="translate(8.227 11.327)" fill="#fff" />
                        </g>
                    </g>
                </svg>
            </div>
            <div className="chat-box">

                <div className="chatbox-inner">
                    <div className="chat-profile">
                        <img className="" src={chatProfile} alt="" />
                    </div>
                    <span className="close-chat"><img className="" src={closeChat} alt="" /></span>
                    <p> <img className="hand-icon" src={handIcon} alt="" /> <span>Hi, We're here to help.</span></p>
                </div>
                <img className="chat-bottom" src={chatbottom} alt="" />
            </div>

        </div>

        {/* --------------banner--------- */}
        <section className="banner api-banner">
            <div className="banner-inner">
                <img src={CloudBanner} alt="" className="api-cloud-banner-img" />
                <img src={APIBanner} alt="" className="api-banner-img" />
                <div className="container">
                    <h1 className="api-title">We’re coming soon.</h1>
                    <h5>Get notified when API page goes live.</h5>
                </div>
            </div>
        </section>
        <section className="main-api-form">
            <div className="api-form">
                <input type="email" placeholder="Enter your e-mail Address" className="api-input" />
                <button className="btn api-btn">NOTIFY ME</button>
            </div>
        </section>
















    </Layout>
)

export default ApiPage
